import { Button, Modal, Tooltip } from "antd";
import { CmsCancelButton, CmsSaveButton } from "../../../common/ButtonComponents";
import Config from "../../../../Config";
import "cropperjs/dist/cropper.css";
import { useRef, useState } from "react";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import ButtonGroup from "antd/es/button/button-group";
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CompressOutlined,
  FullscreenOutlined,
  RetweetOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";

export const CropperModal = ({
  fileId,
  open,
  preSelection,
  onClose,
  onSave,
}: {
  fileId: string;
  open: boolean;
  preSelection?: Cropper.Data;
  onClose: () => void;
  onSave: (selection: Cropper.Data) => void;
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [selection, setSelection] = useState<Cropper.Data | undefined>(preSelection);

  return (
    <Modal
      width={1000}
      style={{ height: "750px", maxHeight: "80%" }}
      title={"Create/edit image crop"}
      open={open}
      closable={true}
      onCancel={() => onClose()}
      footer={[
        <CmsCancelButton key="cancel" onClick={() => onClose()} />,
        <CmsSaveButton
          key="save"
          disabled={!selection}
          onClick={() => {
            if (!!selection) {
              onSave(selection);
            }
          }}
        />,
      ]}
      zIndex={1001}
    >
      <ButtonGroup style={{ margin: "2px" }}>
        <Tooltip title="Crop">
          <Button
            type="primary"
            icon={<CompressOutlined />}
            onClick={() => cropperRef.current?.cropper.setDragMode("crop")}
          />
        </Tooltip>
        <Tooltip title="Move">
          <Button
            type="primary"
            icon={<FullscreenOutlined />}
            onClick={() => cropperRef.current?.cropper.setDragMode("move")}
          />
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup style={{ margin: "2px" }}>
        <Tooltip title="Reset">
          <Button
            type="primary"
            icon={<RetweetOutlined />}
            onClick={() => cropperRef.current?.cropper.reset()}
          />
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup style={{ margin: "2px" }}>
        <Tooltip title="Zoom in">
          <Button
            type="primary"
            icon={<ZoomInOutlined />}
            onClick={() => cropperRef.current?.cropper.zoom(0.1)}
          />
        </Tooltip>
        <Tooltip title="Zoom out">
          <Button
            type="primary"
            icon={<ZoomOutOutlined />}
            onClick={() => cropperRef.current?.cropper.zoom(-0.1)}
          />
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup style={{ margin: "2px" }}>
        <Tooltip title="Move up">
          <Button
            type="primary"
            icon={<ArrowUpOutlined />}
            onClick={() => cropperRef.current?.cropper.move(0, 10)}
          />
        </Tooltip>
        <Tooltip title="Move down">
          <Button
            type="primary"
            icon={<ArrowDownOutlined />}
            onClick={() => cropperRef.current?.cropper.move(0, -10)}
          />
        </Tooltip>
        <Tooltip title="Move right">
          <Button
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={() => cropperRef.current?.cropper.move(10, 0)}
          />
        </Tooltip>
        <Tooltip title="Move left">
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            onClick={() => cropperRef.current?.cropper.move(-10, 0)}
          />
        </Tooltip>
      </ButtonGroup>

      <div style={{ maxHeight: "600px" }}>
        <ReactCropper
          ref={cropperRef}
          id="selection-area"
          src={`${Config.apiUrl}file/${fileId}?imageSize=XL`}
          background={false}
          autoCrop={!!selection}
          style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
          data={selection}
          crop={(x) => setSelection(x.detail)}
        />
      </div>
    </Modal>
  );
};
